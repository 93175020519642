exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-historic-movement-js": () => import("./../../../src/pages/historic-movement.js" /* webpackChunkName: "component---src-pages-historic-movement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memorandum-js": () => import("./../../../src/pages/memorandum.js" /* webpackChunkName: "component---src-pages-memorandum-js" */),
  "component---src-pages-online-convention-js": () => import("./../../../src/pages/online-convention.js" /* webpackChunkName: "component---src-pages-online-convention-js" */),
  "component---src-pages-proposal-of-aikkms-skm-meeting-js": () => import("./../../../src/pages/proposal-of-aikkms-skm-meeting.js" /* webpackChunkName: "component---src-pages-proposal-of-aikkms-skm-meeting-js" */),
  "component---src-pages-resist-all-attacks-of-imperialism-capitalism-js": () => import("./../../../src/pages/resist-all-attacks-of-imperialism-capitalism.js" /* webpackChunkName: "component---src-pages-resist-all-attacks-of-imperialism-capitalism-js" */),
  "component---src-pages-third-all-india-conference-js": () => import("./../../../src/pages/third-all-india-conference.js" /* webpackChunkName: "component---src-pages-third-all-india-conference-js" */)
}

